$(document).ready(function() {
    //
    // DropDown
    //

    (function() {
        if (window.matchMedia("(min-width: 1200px)").matches) {
            $(".dropdown").hover(
                function() {
                    const $this = $(this);
                    $this.addClass("show");
                    $this.find(".dropdown-menu").first().addClass("show");
                },
                function() {
                    const $this = $(this);
                    $this.removeClass("show");
                    $this.find(".dropdown-menu").first().removeClass("show");
                }
            );
            $(document).ready(function() {
                //            计算容易距离页面顶部的高度
                $(window).scroll(function() {
                    //                检测页面滚动的高度
                    var scrollpos = $(window).scrollTop();
                    //                当滚动高度超过距离顶部的高度的时候为容器添加想要的样式
                    if (scrollpos >= 200) {
                        $(".header_cont").addClass("change");
                    } else {
                        $(".header_cont").removeClass("change");
                    }
                });
            });
        } else {
            $(".dropdown").off("mouseenter mouseleave");
            //
            // Slideout
            //

            (function() {
                var slideout = new Slideout({
                    panel: document.getElementById('mu-panel'),
                    menu: document.getElementById('sidebar'),
                    padding: 300,
                    tolerance: 70,
                    easing: 'cubic-bezier(.32,2,.55,.27)',
                    side: 'right',
                    touch: false
                });

                $('.navbar-toggler').on('click', function() {
                    $('.metismenu').removeClass('mm-collapse');
                    slideout.toggle();
                });

                var header = document.getElementById('mu-header');
                var wapmenu = document.getElementById('mu-menu');

                slideout.on('translate', function(translated) {
                    header.style.transform = 'translateX(' + translated + 'px)';
                    wapmenu.style.transform = 'translateX(' + translated + 'px)';
                });

                slideout.on('beforeopen', function() {
                    header.style.transition = 'transform 300ms ease';
                    header.style.transform = 'translateX(-300px)';
                    wapmenu.style.transition = 'transform 300ms ease';
                    wapmenu.style.transform = 'translateX(-300px)';
                });

                slideout.on('beforeclose', function() {
                    header.style.transition = 'transform 300ms ease';
                    header.style.transform = 'translateX(0px)';
                    wapmenu.style.transition = 'transform 300ms ease';
                    wapmenu.style.transform = 'translateX(0px)';
                });

                new MetisMenu('#sidebar');
            })();

            //
            // 导航动效
            //

            (function() {
                var docElem = document.documentElement,
                    header = $("#header"),
                    didScroll = false;

                scrollPage();
                window.addEventListener(
                    "scroll",
                    function(event) {
                        if (!didScroll) {
                            didScroll = true;
                            setTimeout(scrollPage, 250);
                        }
                    },
                    false
                );

                function scrollPage() {
                    var scrollY = window.pageYOffset || docElem.scrollTop;
                    if (scrollY >= 150) {
                        header.removeClass("expand");
                    } else {
                        header.addClass("expand");
                    }
                    didScroll = false;
                }
            })();

        }

        // DropDown Submenu
        $(".dropdown-submenu a[data-toggle='dropdown']").on("click", function(e) {
            if (!$(this).next().hasClass("show")) {
                $(this)
                    .parents(".dropdown-menu")
                    .first()
                    .find(".show")
                    .removeClass("show");
            }

            var $subMenu = $(this).next(".dropdown-menu");
            $subMenu.toggleClass("show");

            $(this)
                .parents("li.nav-item.dropdown.show")
                .on("hidden.bs.dropdown", function(e) {
                    $(".dropdown-submenu .show").removeClass("show");
                });

            return false;
        });

        // Nav Submenu
        $('a[data-toggle="submenu"]').on("click", function(e) {
            e.preventDefault();
            $(this).next(".nav-submenu").toggleClass("show");
        });
    })();

    //
    // Scroll To Top
    //

    (function() {
        var $document = $(document);
        var $scrollTotop = $(".scroll-to-top");
        if ($scrollTotop.length > 0) {
            if ($document.scrollTop() > 300) {
                $scrollTotop.show();
            }
            $document.on("scroll", function() {
                if ($document.scrollTop() > 300) {
                    $scrollTotop.show();
                } else {
                    $scrollTotop.fadeOut();
                }
            });
            $scrollTotop.click(function(e) {
                e.preventDefault();
                $("html, body").animate({
                        scrollTop: 0,
                    },
                    500
                );
                return false;
            });
        }
    })();



    //
    // 百度地图
    //

    (function() {
        var mapBox = $("#map-box");
        var mapBoxWidth = mapBox.width();
        if (mapBoxWidth > 260) {
            mapBoxWidth = 260;
        }
        mapBox.height(mapBoxWidth);

        if (mapBox.length > 0) {
            var mapInfo = $.extend({
                    lng: 87.586399, // 经度
                    lat: 43.875055, // 纬度
                    title: "新疆百疆图网络服务有限公司", // 公司名称
                    message: "地址：乌市新市区长沙路158号绿苑雅筑2号楼4楼", // 地址
                },
                window.MAP_INFO
            );

            // 百度地图API功能
            var map = new BMap.Map("map-box");
            var point = new BMap.Point(mapInfo.lng, mapInfo.lat);
            var marker = new BMap.Marker(point); // 创建标注
            map.addOverlay(marker); // 将标注添加到地图中
            map.enableScrollWheelZoom(true); //开启鼠标滚轮缩放
            map.centerAndZoom(point, 18);
            marker.setAnimation(BMAP_ANIMATION_BOUNCE); //跳动的动画

            var opts = {
                offset: {
                    height: -20,
                    width: -6
                },
                width: 200, // 信息窗口宽度
                height: 80, // 信息窗口高度
                title: mapInfo.title, // 信息窗口标题
                enableMessage: true, //设置允许信息窗发送短息
                message: mapInfo.message,
            };
            var infoWindow = new BMap.InfoWindow(mapInfo.message, opts); // 创建信息窗口对象
            map.openInfoWindow(infoWindow, point); //开启信息窗口
            marker.addEventListener("click", function() {
                this.openInfoWindow(infoWindow); //开启信息窗口
            });

            var top_left_navigation = new BMap.NavigationControl(); //左上角，添加默认缩放平移控件

            map.addControl(top_left_navigation);
        }
    })();

    // ajax表单验证及提交
    // sweetalert 参数文档地址 https://sweetalert.js.org/
    (function() {

        //
        // swalModel
        //

        function swalModel(text, icon) {
            swal({
                title: '温馨提示',
                text: text,
                icon: icon,
                buttons: false,
                timer: 3000
            })
        }

        //
        // Form Submit
        //

        $('#messageSub').on('click', function() {
            var name = $(':input[name="name"]').val();
            var phone = $(':input[name="phone"]').val();
            var email = $(':input[name="email"]').val();

            var phone_reg = /(^(?:(?:0\d{2,3})-)?(?:\d{7,8})(-(?:\d{3,}))?$)|(^0{0,1}1[3|4|5|6|7|8|9][0-9]{9}$)/;
            var email_reg = /^([a-zA-Z0-9_-])+@([a-zA-Z0-9_-])+(.[a-zA-Z0-9_-])+/;

            // 验证
            if (name.length < 2 || name.length > 30) {
                swalModel('姓名错误，请重新输入！', 'error');
                return;
            }
            if (!phone_reg.test(phone)) {
                swalModel('电话号码错误，请重新输入！', 'error');
                return;
            }
            if (!email_reg.test(email)) {
                swalModel('邮箱错误，请重新输入！', 'error');
                return;
            }

            var datas = $('#message').serialize();

            $.post("/api/messages", datas, function(res) {
                //表单提交成功
                if (res.code === 1) {
                    swalModel('留言成功！', 'success');
                    setTimeout(function() {
                        // 清空表单
                        $('#message')[0].reset();
                    }, 2000);
                }
            });
        })
    })();
});
